/**
 * Helps get the right localization of the provided group.
 * It finds suitable fallbacks when the requested form is not present.
 *
 * @param {object} groupingsConfig - A (sub)group object, we want to get the localization for.
 *
 * @param {object} options
 * @param {Boolean} options.singular - If both singular and plural forms are available it defaults to plural, unless this option is true.
 * @param {String} options.type - 'long' or 'short' - Will return the 'long' or 'short' localization if available, with a fallback to the 'normal' localization.
 * @param {String} options.locale - locale of the localization, with a fallback to 'nl'
 * @returns {string} - a localized string
 */
// NOTE: This function is copied from shared grouping_service. They should be kept in sync
const getGroupLocalization = (groupingsConfig, options = {}) => {
  const { singular, type, locale = 'nl' } = options;
  let localization = groupingsConfig[`${type}Localization`] || groupingsConfig.localization;
  localization = localization[locale] || localization.nl;
  return typeof localization === 'string' ? localization : localization[singular ? 'singular' : 'plural'];
};

module.exports = {
  getGroupLocalization,
};
